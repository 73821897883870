import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { Player, ControlBar, BigPlayButton } from 'video-react'
import { formatImageTransform, formatKey } from '../../lib/utils'

import './projectCards.scss'
import Img from '../img/img'
import { ElementFilter, ElementFilters } from '../elementFilter/elementFilter'

const SETTINGS = {
  imgSizesSmall: '(min-width: 600px) 50vw, 100vw',
  imgSizesLarge: '100vw',
  classSmall: 'projectCards__item',
  classLarge: 'projectCards__item projectCards__item--large',
}

const ImageThumbnail = ({ thumbnail, lazy, small }) => {
  const { images, alt } = formatImageTransform(thumbnail[0])
  const sizes = small ? SETTINGS.imgSizesSmall : SETTINGS.imgSizesLarge

  return <Img sources={images} sizes={sizes} alt={alt} lazy={lazy} />
}

const VideoThumbnail = ({ thumbnail }) => (
  <Player muted loop fluid autoPlay playsInline>
    {thumbnail.map(({ url, title }, i) => {
      const key = `video-source-${formatKey(title)}-${i}`
      return <source src={url} key={key} />
    })}

    <ControlBar disabled />
    <BigPlayButton disabled />
  </Player>
)

const Card = ({ small = true, ...props }) => {
  const className = small ? SETTINGS.classSmall : SETTINGS.classLarge,
    typename = props.thumbnail[0].__typename

  return (
    <TransitionLink
      className={className}
      to={'/' + props.uri}
      entry={{
        length: 0.1,
        zIndex: '',
      }}
    >
      <ElementFilters>
        <figure className="projectCards__img">
          {
            {
              Craft_ImagesVolume: <ImageThumbnail {...props} small={small} />,
              Craft_VideosVolume: <VideoThumbnail {...props} small={small} />,
            }[typename]
          }
        </figure>

        <div>
          <h2 className="projectCards__title">{props.title}</h2>
        </div>
      </ElementFilters>
    </TransitionLink>
  )
}

function ProjectCards({ projects }) {
  return (
    <ElementFilter>
      <div className="projectCards">
        {projects.map(item => (
          <Card
            {...item}
            key={`project-card-${item.uri}`}
            lazy={true}
            small={!item.fullWidthThumbnail}
          />
        ))}
      </div>
    </ElementFilter>
  )
}

export default ProjectCards
