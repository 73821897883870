import React, { useEffect, useState } from 'react'

// import useInterval from '../../hooks/useInterval/useInterval'
import Img from '../img/img'
import { formatImageTransform, delay } from '../../lib/utils'

function SlideshowImage({ image, scale, onLoad }) {
  const { images: sources, alt } = formatImageTransform(image)

  return (
    <div className="slideshowPlayer__img">
      <Img
        sources={sources}
        alt={alt}
        lazy={false}
        sizes="50vw"
        onLoad={onLoad}
      />
    </div>
  )
}

function Player({ images, onClick, speed }) {
  const [activeImages, setActiveImages] = useState([])

  const addImage = () => {
    const nextIndex = activeImages.length % images.length
    const next = { image: images[nextIndex] }

    const [...newActiveImages] = [...activeImages]
    newActiveImages.push(next)

    setActiveImages(newActiveImages)
  }

  const addImageDelayed = delay(addImage, speed)
  // const addImageDelayed = () => console.log('addImageDelayed')

  useEffect(addImage, [])
  // useInterval(addImage, speed)

  return (
    <div class="slideshowPlayer" onClick={onClick}>
      {activeImages.map((props, i) => (
        <SlideshowImage
          {...props}
          key={'slideshow-image-' + i}
          onLoad={addImageDelayed}
        />
      ))}
    </div>
  )
}

export default Player
