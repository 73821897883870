import React, { useState, useEffect } from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'

import { useGlobalContext } from '../../state/global'
import SetFilter from '../setFilter/setFilter'

const minimal = (min, int) => (int > min ? int : min)

const calculateLength = (scrollTop, containerHeight, modalTransition) => {
  if (!modalTransition) return 0

  containerHeight = minimal(2, containerHeight)

  const animationDuration = 2,
    scrollTopPercent =
      scrollTop / ((containerHeight - window.innerHeight * 0.5) / 100)

  return (animationDuration / 100) * (100 - scrollTopPercent)
}

function TransitionLinkHome({ children = false, ...props }) {
  const [{ scrollTop, containerHeight, modalTransition }] = useGlobalContext()
  const [length, setLength] = useState(2)

  useEffect(() => {
    setLength(calculateLength(scrollTop, containerHeight, modalTransition))
  }, [scrollTop, containerHeight, modalTransition])

  return (
    <SetFilter mode="off">
      <TransitionLink to="/" exit={{ length, zIndex: 1 }} {...props}>
        {children && children}
      </TransitionLink>
    </SetFilter>
  )
}

export default TransitionLinkHome
