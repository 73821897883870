'use strict'

var _require = require('gatsby'),
  navigate = _require.navigate

exports.wrapPageElement = require('./wrap-page')

exports.shouldUpdateScroll = function() {
  return false
}

exports.onPreRouteUpdate = function(_ref) {
  var location = _ref.location

  // prevent the back button during transitions as it breaks pages
  if (
    window.__tl_inTransition &&
    location.pathname !== window.__tl_desiredPathname
  ) {
    window.__tl_back_button_pressed = true
    navigate(window.__tl_desiredPathname)
  }
}
