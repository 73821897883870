import React, { useState } from 'react'

import './slideshow.scss'
import { ElementFilter } from '../elementFilter/elementFilter'
import PageStatus from '../pageStatus/pageStatus'
import Icon from './slideshowIcon'
import Player from './slideshowPlayer'

function Slideshow({
  icon,
  images,
  onStop = false,
  onPlay = false,
  speed = 500,
}) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [zIndex, setZIndex] = useState(1)

  const play = () => {
    setIsPlaying(true)
    if (onPlay) onPlay()
  }

  const stop = () => {
    setIsPlaying(false)
    if (onStop) onStop()
  }

  const bringIconForward = () => setZIndex(2)
  const bringIconBackward = () => setZIndex(1)

  return (
    <PageStatus
      onEntering={{ about: bringIconForward }}
      onExited={{ about: bringIconBackward }}
    >
      <ElementFilter exclude={['about']}>
        <Icon
          className="slideshowIcon"
          icon={icon}
          onClick={play}
          style={{ zIndex }}
        />
      </ElementFilter>
      {isPlaying && <Player images={images} onClick={stop} speed={speed} />}
    </PageStatus>
  )
}

export default Slideshow
