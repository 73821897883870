import R from 'ramda'

export const log = value => {
  console.log(value)
  return value
}

export const getEntryFromData = data => data.craft.entry
export const formatImageTransform = data => {
  // Extract title from data
  const alt = data.title

  // Omit unnecessary properties
  data = R.omit(['__typename', 'title', 'id', 'width', 'height'], data)

  // Extract width and url from data
  const images = R.pipe(
    R.toPairs,
    R.reduce((acc, [key, value]) => {
      const type = key.slice(0, 2),
        modifier = key.slice(2).toLowerCase()

      // Create new type object
      acc[type] = acc[type] ? acc[type] : {}

      // Assign modifier
      acc[type][modifier] = value

      return acc
    }, {})
  )(data)

  return {
    images,
    alt,
  }
}

export const formatKey = str => str.toLowerCase().replace(' ', '')

export const debounce = (func, wait, immediate) => {
  var timeout
  return function() {
    var context = this,
      args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const throttle = (func, limit) => {
  let inThrottle
  return function() {
    const args = arguments
    const context = this
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      setTimeout(() => (inThrottle = false), limit)
    }
  }
}

export const getDocumentHeight = () => {
  const body = document.body,
    html = document.documentElement

  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )

  return height
}

export const disableScrolling = () => {
  document.querySelector('html').style.overflow = 'hidden'
}

export const enableScrolling = () => {
  document.querySelector('html').style.overflow = ''
}

export const startAnimation = fn => {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      fn()
    })
  })
}

export const delay = (fn, ms) => props => {
  setTimeout(() => {
    fn(props)
  }, ms)

  return props
}

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')
}

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
}
