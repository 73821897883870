import React from 'react'
import R from 'ramda'

// Wrap the require in check for window
if (typeof window !== `undefined`) {
  require('lazysizes')
  require('lazysizes/plugins/attrchange/ls.attrchange')
}

// Utils
const srcSetStringReducer = (acc, src) => {
  acc = acc.length ? acc + ',' : acc

  return `${acc + src.url} ${src.width}w`
}

const createPaddingTopStyling = (width, height) => ({
  paddingTop: `${(height / width) * 100}%`,
})

const createSrcsetString = R.pipe(
  R.values,
  R.reduce(srcSetStringReducer, '')
)

// Component
function Img({
  sources,
  sizes = '100vw',
  alt = false,
  lazy = true,
  width,
  height,
  setPadding = false,
  onLoad = false,
}) {
  if (R.isEmpty(sources)) return <></>

  const srcsetProp = lazy ? 'data-srcset' : 'srcSet',
    srcProp = lazy ? 'data-src' : 'src'

  const containerProperties = {},
    properties = {
      [srcsetProp]: createSrcsetString(sources),
      [srcProp]: sources.lg ? sources.lg.url : sources.md.url,
      sizes,
    }

  // Conditional img properties
  if (lazy) properties.className = 'lazyload'
  if (alt) properties.alt = alt
  if (onLoad) properties.onLoad = onLoad

  // Conditional container properties
  if (setPadding)
    containerProperties.style = createPaddingTopStyling(width, height)

  // Render container is any properties exist
  if (!R.isEmpty(containerProperties)) {
    return (
      <div className="fixedRatio" {...containerProperties}>
        <img {...properties} />
      </div>
    )
  }

  return <img {...properties} />
}

export default Img
