import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import './footer.scss'
import { ElementFilter } from '../elementFilter/elementFilter'

const Item = ({ url, text }) => (
  <li className="footer__item">
    <a href={url} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  </li>
)

function Footer() {
  const { email, socialMedia } = useStaticQuery(graphql`
    query {
      craft {
        globals {
          general {
            email
            socialMedia {
              ... on Craft_SocialMediaItem {
                platform
                profile
              }
            }
          }
        }
      }
    }
  `).craft.globals.general

  return (
    <ElementFilter>
      <footer className="footer h1">
        <ul className="footer__list">
          <Item url={`mailto:${email}`} text="E-mail" key="email" />

          {socialMedia.map(({ platform, profile }) => (
            <Item url={profile} text={platform} key={platform} />
          ))}
        </ul>
      </footer>
    </ElementFilter>
  )
}  

export default Footer
