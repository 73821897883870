import React from 'react'

import { useGlobalContext } from '../../state/global'

function SetFilter({ mode, children }) {
  const [, dispatchGlobal] = useGlobalContext()

  const onClick = () => {
    dispatchGlobal({ type: 'setIsFilterOn', newIsFilterOn: mode === 'on' })
  }

  return React.cloneElement(children, { onClick })
}

export default SetFilter
