import React, { useEffect } from 'react'

import { useGlobalContext } from '../../state/global'

function PageStatus({
  children,
  onEntering = {},
  onEntered = {},
  onExiting = {},
  onExited = {},
}) {
  const [
    { pageEntering, pageEntered, pageExiting, pageExited },
  ] = useGlobalContext()

  // Enter
  useEffect(() => {
    if (onEntering[pageEntering]) onEntering[pageEntering]()
  }, [pageEntering])

  useEffect(() => {
    if (onEntered[pageEntered]) onEntered[pageEntered]()
  }, [pageEntered])

  // Exit
  useEffect(() => {
    if (onExiting[pageExiting]) onExiting[pageExiting]()
  }, [pageExiting])

  useEffect(() => {
    if (onExited[pageExited]) onExited[pageExited]()
  }, [pageExited])

  return <>{children}</>
}

export default PageStatus
