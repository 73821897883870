import React, { useEffect } from 'react'
import { throttle, getDocumentHeight } from '../../lib/utils'

let APP_STATE = {
  status: 'up',
  lastY: 0,
  initialized: false,
  handlers: {
    down: [],
    up: [],
    bottom: [],
    top: [],
  },
}

// Utils
const addHandler = (STATE, name) => fn => {
  STATE.handlers[name].push(fn)
}

const removeHandler = (STATE, name) => fn => {
  const arr = STATE.handlers[name]
  const i = arr.indexOf(fn)
  arr.splice(i, 1)
}

const trigger = STATE => {
  STATE.handlers[STATE.status].forEach(handler => handler())
  STATE.changed = false

  return STATE
}

const addUpHandler = addHandler(APP_STATE, 'up')
const removeUpHandler = removeHandler(APP_STATE, 'up')
const addDownHandler = addHandler(APP_STATE, 'down')
const removeDownHandler = removeHandler(APP_STATE, 'down')
const addBottomHandler = addHandler(APP_STATE, 'bottom')
const removeBottomHandler = removeHandler(APP_STATE, 'bottom')
const addTopHandler = addHandler(APP_STATE, 'top')
const removeTopHandler = removeHandler(APP_STATE, 'top')

const updateStatus = STATE => {
  const last = STATE.lastY,
    current = window.scrollY,
    windowHeight = window.innerHeight,
    documentHeight = getDocumentHeight()

  // Check if up or down
  if (last < current && STATE.status !== 'down') {
    STATE.status = 'down'
    STATE.changed = true
  } else if (last > current && STATE.status !== 'up') {
    STATE.status = 'up'
    STATE.changed = true
  }

  // Check if bottom or top is reached
  if (current < 200) {
    STATE.status = 'top'
    STATE.changed = true
  } else if (current + windowHeight > documentHeight - 200) {
    STATE.status = 'bottom'
    STATE.changed = true
  }

  STATE.lastY = current

  return STATE
}

const init = () => {
  APP_STATE.initialized = true

  // Initialize
  window.addEventListener(
    'scroll',
    throttle(() => {
      APP_STATE = updateStatus(APP_STATE)

      if (APP_STATE.changed) APP_STATE = trigger(APP_STATE)
    }, 50)
  )
}

// Component
function Scroll({
  children,
  onUp = false,
  onDown = false,
  onBottom = false,
  onTop = false,
}) {
  useEffect(() => {
    if (!APP_STATE.initialized) init()

    if (onUp) addUpHandler(onUp)
    if (onDown) addDownHandler(onDown)
    if (onBottom) addBottomHandler(onBottom)
    if (onTop) addTopHandler(onTop)

    return () => {
      if (onUp) removeUpHandler(onUp)
      if (onDown) removeDownHandler(onDown)
      if (onBottom) removeBottomHandler(onBottom)
      if (onTop) removeTopHandler(onTop)
    }
  }, [])

  return <>{children}</>
}

export default Scroll
