import React, { useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import R from 'ramda'

import './menu.scss'
import Scroll from '../scroll/scroll'
import TransitionLinkHome from '../transitionLinkHome/transitionLinkHome'
import PageStatus from '../pageStatus/pageStatus'
import { useGlobalContext } from '../../state/global'

const SETTINGS = {
  expandClass: 'menu--expand',
  collapseClass: 'menu--collapse h1--shrink',
  transparentBackgroundClass: 'menu--transparent',
  aboutTitleOpen: 'etc.',
  aboutTitleClose: 'esc.',
}

const toArr = src => src.split('')
const reachedTop = () => (window.scrollY < 100 ? true : false)
const whenReachedTop = R.when(reachedTop)

// Component
function Menu() {
  const menuTitle = metaTitleQuery()
  const [modifier, setModifier] = useState('menu--expand')
  const [backgroundModifier, setBackgroundModifier] = useState('')
  const [aboutTitle, setAboutTitle] = useState(toArr(SETTINGS.aboutTitleOpen))
  const [{ page }] = useGlobalContext()

  const expand = () => {
    setModifier(SETTINGS.expandClass)
  }
  const expandAtTop = whenReachedTop(expand)

  const collapse = () => {
    setModifier(SETTINGS.collapseClass)
  }

  const onEnteringAbout = () => {
    setAboutTitle(toArr(SETTINGS.aboutTitleClose))
    setBackgroundModifier(SETTINGS.transparentBackgroundClass)
  }

  const onExitingAbout = () => {
    setAboutTitle(toArr(SETTINGS.aboutTitleOpen))
    setBackgroundModifier('')
  }

  return (
    <PageStatus
      onEntering={{ about: onEnteringAbout, project: collapse }}
      onExiting={{ about: onExitingAbout, project: () => expandAtTop(1) }}
    >
      <Scroll onUp={expand} onDown={collapse} onTop={expand} />

      <nav className={`menu h1 ${modifier} ${backgroundModifier}`}>
        <h1 className="menu__title">
          <TransitionLinkHome>{menuTitle}</TransitionLinkHome>
        </h1>

        <Link className="menu__etc" to={page === 'about' ? '/' : '/about'}>
          {aboutTitle.map(char => (
            <span>{char}</span>
          ))}
        </Link>
      </nav>
    </PageStatus>
  )
}

// Query
const metaTitleQuery = R.pipe(
  () =>
    useStaticQuery(
      graphql`
        query {
          craft {
            globals {
              general {
                menuTitle
              }
            }
          }
        }
      `
    ),
  R.path(['craft', 'globals', 'general', 'menuTitle'])
)

export default Menu
