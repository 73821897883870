import React, { useEffect, useState } from 'react'
import { replaceAll } from '../../lib/utils'
import R from 'ramda'

import { useGlobalContext } from '../../state/global'
import './elementFilter.scss'

const SETTINGS = {
  colors: ['#ff0', '#f00'],
  activeClass: 'elementFilter--active',
}

// Get
const getRandomColor = colors =>
  colors[Math.floor(Math.random() * colors.length)]

// Bool
const isExcluded = (page, exclude) =>
  exclude.indexOf(page) > -1 ? true : false

const isNotActive = className => {
  let isActive = className.indexOf(SETTINGS.activeClass) > -1 ? true : false

  return isActive ? false : true
}

// Create
const createStyle = colors => ({
  color: getRandomColor(colors),
})

const createClassName = ([isFilterOn, className, isExcluded]) =>
  !isFilterOn || isExcluded
    ? replaceAll(className, SETTINGS.activeClass, '')
    : isNotActive(className)
    ? `${className} ${SETTINGS.activeClass}`
    : className

const createInitialClass = className => (className || '') + ' elementFilter'

// Elements
function ElementFilters({ children }) {
  return (
    <>
      {children.map(child => (
        <ElementFilter>{child}</ElementFilter>
      ))}
    </>
  )
}

function ElementFilter({ children, exclude = [] }) {
  const [{ isFilterOn, colors, page }] = useGlobalContext()
  const [customStyle, setCustomStyle] = useState()
  const [className, setClassName] = useState(
    createInitialClass(children.props.className)
  )

  const createAndSetStyle = R.pipe(
    createStyle,
    setCustomStyle
  )
  const createAndSetClassName = R.pipe(
    createClassName,
    setClassName
  )

  useEffect(() => {
    createAndSetStyle(colors)
  }, [colors])

  useEffect(() => {
    createAndSetClassName([isFilterOn, className, isExcluded(page, exclude)])
  }, [isFilterOn, page])

  return (
    <>
      {React.cloneElement(children, {
        style: { ...children.props.style, ...customStyle },
        className,
      })}
    </>
  )
}

export { ElementFilter, ElementFilters }
