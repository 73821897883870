import React from 'react'
import PropTypes from 'prop-types'

import './wrapper.scss'

function Wrapper({ children, useMarginTop }) {
  const className = useMarginTop ? 'wrapper wrapper--marginTop' : 'wrapper'

  return <main className={className}>{children}</main>
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Wrapper
