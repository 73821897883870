import React, { createContext, useContext, useReducer } from 'react'

let initialState = {
  scrollTop: 0,
  containerHeight: 0,
  isFilterOn: false,
  colors: [],
  modalTransition: true,
  page: '',
  isTouchDevice: true,
  touchDeviceIsSet: false,
  pageEntering: '',
  pageEntered: '',
  pageExiting: '',
  pageExited: '',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setScrollTop':
      return {
        ...state,
        scrollTop: action.newScrollTop,
      }

    case 'setContainerHeight':
      return {
        ...state,
        containerHeight: action.newContainerHeight,
      }

    case 'setIsFilterOn':
      return {
        ...state,
        isFilterOn: action.newIsFilterOn,
      }

    case 'setColors':
      return {
        ...state,
        colors: action.newColors,
      }

    case 'setFastModalTransition':
      return {
        ...state,
        fastModalTransition: action.newFastModalTransition,
      }

    case 'setIsTouchDevice':
      return {
        ...state,
        isTouchDevice: action.newIsTouchDevice,
      }

    case 'setTouchDeviceIsSet':
      return {
        ...state,
        TouchDeviceIsSet: action.newTouchDeviceIsSet,
      }

    case 'setPage':
      return {
        ...state,
        page: action.newPage,
      }

    case 'setPageEntering':
      return {
        ...state,
        pageEntering: action.newPageEntering,
      }

    case 'setPageEntered':
      return {
        ...state,
        pageEntered: action.newPageEntered,
      }

    case 'setPageExiting':
      return {
        ...state,
        pageExiting: action.newPageExiting,
      }

    case 'setPageExited':
      return {
        ...state,
        pageExited: action.newPageExited,
      }

    default:
      return state
  }
}

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => (
  <GlobalContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </GlobalContext.Provider>
)

export const useGlobalContext = () => useContext(GlobalContext)
