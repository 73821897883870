import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import R from 'ramda'
import * as utils from '../../lib/utils'

import '../../sass/base/index.scss'
import { GlobalProvider, useGlobalContext } from '../../state/global'
import Menu from '../menu/menu'
import Footer from '../footer/footer'
import Wrapper from '../wrapper/wrapper'
import ProjectCards from '../projectCards/projectCards'
import Slideshow from '../slideshow/slideshow'

// Utils
const getColors = R.path(['craft', 'globals', 'general', 'colors'])
const getHex = R.path(['hex', 'hex'])
const formatColors = R.map(getHex)
const getAndFormatColors = R.pipe(
  getColors,
  formatColors
)

// Components
const UpdateColors = ({ colors }) => {
  const [, dispatchGlobal] = useGlobalContext()

  useEffect(() => {
    dispatchGlobal({ type: 'setColors', newColors: colors })
  }, [])

  return <></>
}

const UpdateIsTouchDevice = () => {
  const [{ touchDeviceIsSet }, dispatchGlobal] = useGlobalContext()

  useEffect(() => {
    if (!touchDeviceIsSet) {
      const checkMouseMove = () => {
        dispatchGlobal({ type: 'setIsTouchDevice', newIsTouchDevice: false })

        window.removeEventListener('mousemove', checkMouseMove, false)
      }

      window.addEventListener('mousemove', checkMouseMove)

      window.addEventListener('touchstart', () => {
        window.removeEventListener('mousemove', checkMouseMove)
      })

      dispatchGlobal({ type: 'setTouchDeviceIsSet', newTouchDeviceIsSet: true })
    }
  }, [touchDeviceIsSet])

  return <></>
}

function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          globals {
            slideshow {
              toggle
              speed
              icon {
                url
              }
              images {
                smUrl: url(transform: gallerySm)
                smWidth: width(transform: gallerySm)

                mdUrl: url(transform: galleryMd)
                mdWidth: width(transform: galleryMd)

                # lgUrl: url(transform: galleryLg)
                # lgWidth: width(transform: galleryLg)

                # xlUrl: url(transform: galleryXl)
                # xlWidth: width(transform: galleryXl)

                title
              }
            }
            general {
              colors {
                ... on Craft_ColorsColor {
                  hex {
                    hex
                  }
                }
              }
            }
          }
          projects: entries(section: projects) {
            ... on Craft_Projects {
              uri
              slug
              title
              subtitle
              fullWidthThumbnail
              thumbnail {
                ... on Craft_ImagesVolume {
                  smUrl: url(transform: projectCardSm)
                  smWidth: width(transform: projectCardSm)

                  mdUrl: url(transform: projectCardMd)
                  mdWidth: width(transform: projectCardMd)

                  lgUrl: url(transform: projectCardLg)
                  lgWidth: width(transform: projectCardLg)

                  xlUrl: url(transform: projectCardXl)
                  xlWidth: width(transform: projectCardXl)

                  title
                }

                ... on Craft_VideosVolume {
                  url
                  title
                }
              }
            }
          }
        }
      }
    `
  )
  
  const projects = data.craft.projects
  const slideshow = data.craft.globals.slideshow
  const colors = getAndFormatColors(data)
  const speed = slideshow.speed ? Number(slideshow.speed) * 1000 : 1000

  return (
    <GlobalProvider>
      <UpdateColors colors={colors} />
      <UpdateIsTouchDevice />

      <Menu />
      <Wrapper>
        <ProjectCards projects={projects} />
      </Wrapper>
      <Footer />
      {slideshow.toggle && (
        <Slideshow
          icon={slideshow.icon[0].url}
          images={slideshow.images}
          speed={speed}
          onPlay={utils.disableScrolling}
          onStop={utils.enableScrolling}
        />
      )}
      {children}
    </GlobalProvider>
  )
}

export default Layout
